.player-chooser {
  font-family: "Korinna-Bold";
}

.player-chooser input {
  height: 20px;
  margin: 10px;
  width: 200px;
}

.player-chooser button {
  width: 100px;
  height: 25px;
  border: none;
  border-radius: 3px;
}

.add-player-button {
}

.play-game-button {
  background-color: #61c27b;
}
