body {
  background-color: black;
  color: white;
}

table {
  border: 3px solid black;
  border-collapse: collapse;
  table-layout: fixed;
  background-color: #001699;
  color: white;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-shadow: 2px 2px black;
}

td {
  border: 5px solid black;
  padding: 20px;
  width: 140px;
  text-align: center;
}

.category-container {
  height: 600px;
}

.category-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 600px;
  background-color: #001699;
  float: left;
}

.categorybox-enter {
  margin-left: 110%;
}

.categorybox-enter-active {
  margin-left: 0%;
  transition: margin-left 1000ms ease;
}

.categorybox-exit {
  margin-left: 0%;
  position: absolute;
}

.categorybox-exit-active {
  margin-left: -110%;
  transition: margin-left 1000ms ease;
}

.category-box .category {
  font-family: "Swiss921", sans-serif;
  text-transform: uppercase;
  font-size: 120px;
  padding-left: 100;
}

td.category-title {
  font-family: "Swiss921", sans-serif;
  font-size: 28px;
  text-transform: uppercase;
}

td.board-clue {
  font-family: "Swiss921", sans-serif;
  font-size: 50px;
  height: 65px;
  color: #c99f57;
}

.clue {
  padding-left: 10px;
  padding-right: 10px;
}

.clue-display {
  background-color: #001699;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Korinna", serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 50px;
  width: 100%;
  min-height: 600px;
  box-sizing: border-box;
  padding: 20px;
}

.final-category {
  font-family: "Swiss921", sans-serif;
  font-size: 90px;
  font-weight: normal;
}

.daily-double {
  font-size: 90px;
}

.clue-category-label {
  color: white;
  font-family: "Korinna", serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  padding: 10px;
}

@import url("https://fonts.googleapis.com/css?family=Fira+Code&display=swap");
.code {
  font-family: "Consolas", "Fira Code", monospace;
  text-transform: none;
}

.code-lines {
  list-style-type: none;
  font-family: "Consolas", "Fira Code", monospace;
  text-transform: none;
  text-align: left;
}
